
  export default {
    name: 'Modal',
    methods: {
      reject() {
        (this as any).$emit('reject');
      },
      accept() {
        (this as any).$emit('accept');
      },
    },
  };
